/* VideoModal.css */
.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .video-modal {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .video-frame {
    width: 560px;
    height: 315px;
    border-radius: 10px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #ff4b5c;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .close-button:hover {
    transform: scale(1.2);
  }
  