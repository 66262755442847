/* index.css */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #ff66b2, #6a0dad);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

button {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.2s ease, background 0.3s ease;
}

button:hover {
  transform: scale(1.1);
}

.hidden {
  display: none;
}
