.lock-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: linear-gradient(135deg, #ff66b2, #6a0dad); A shade to match your theme */
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.lock-box {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.relationship-counter {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.seconds-counter {
  font-size: 2rem;
  font-weight: bold;
  color: #ff4b5c; /* Red color to match the Valentine's theme */
}

.password-input {
  padding: 10px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1.1rem;
}

.unlock-button {
  padding: 10px 20px;
  background-color: #ff4b5c;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

.unlock-button:hover {
  background-color: #ff6f80;
}

.error-text {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}
