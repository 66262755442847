.valentine-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .valentine-text {
    font-size: 2.5rem;
    font-weight: bold;
    animation: fadeIn 1s ease-in-out;
  }
  
  .heart-container {
    margin: 20px 0;
  }
  
  .buttons {
    display: flex;
    gap: 20px;
  }
  
  .yes-button {
    background-color: #4CAF50;
    color: white;
    position: relative;
    transition: transform 0.3s ease; /* Smooth transition for scaling */
  }
  
  .no-button {
    position: absolute; /* Make sure the button can move freely */
    transition: all 0.3s ease; /* Smooth transition when it moves */
    transform: translate(-50%, -50%); /* Center the button on the random position */
  }
  
  .no-button:hover {
    left: 100px; /* Move the button to the right when hovered */
    top: 30px;  /* You can adjust this value to make the movement more dramatic */
  }
  